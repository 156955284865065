import React from "react"
import { Link } from "gatsby"
import Head from "../components/head"
import Header from "../components/header"
import Nav from "../components/nav"
import styles from "../styles/global.css"
import media from "../styles/media.css"
import Footer from "../components/footer"
import { LoginHeader } from "../components/nav"
import { SocialHeader } from "../components/social"

// export default function Home() {
//   return <div>Hello world!</div>
// }

export default () => (
  <>
    <Header titleText="Pricing - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
        <div className="loginheader_div">
          <div className="loginheader">
            <div className="loginheaderlist_div">
              <img
                alt="icon doller"
                src="/sites/default/files/icon_doller.png"
              />
              <a className="current_menu" rel="" href="/pricing/">
                Pricing
              </a>
            </div>
            <div className="loginheaderlist_div">
              <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                target="_blank"
              >
                Free Trial
              </a>
            </div>

            <div className="loginheaderlist_div">
              <img alt="user" src="/sites/default/files/user.png" />
              <span className="login_span">
                <a rel="" href="javascript:;">
                  Log In
                </a>
                <ul className="login_ul_son">
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://user.happymangocredit.com"
                      target="_blank"
                    >
                      Individual/Business
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://institution.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Institutions
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://advisor.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Coaches
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Head />

      <div className="pricing">
        <h1>AFFORDABLE PRICING</h1>
        <ul className="price_ul">
          <li className="price_li item_title">
            <div className="price_li_div"></div>
            <ul class="pricing_item_title">
              <li>Number of Operating Licenses</li>
              <li>New Customers per Month</li>
              <li>New Applications per Month</li>
              <li>New Loans Made per Month</li>
              <li>Access to Automated Email and Text</li>
              <li>Access to Cash Flow Analytical Tools</li>
              <li>Access to Collaboration Tools</li>
              <li>Access to Digital Marketing Tools</li>
              <li>Access to Supported Financial Products</li>
              <li>Access to Consumer Credit Reporting *</li>
              <li>Access to Management Reporting</li>
              <li>Smart ACH Transfers *</li>
            </ul>
          </li>
          <li className="price_li">
            <div className="price_li_div">
              <h2>Basic</h2>
              <div className="title_price">
                $415 per Month
                <br />
                $4,500 One - Time Set Up Fee
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=1"
                className="banks_freetrial"
              >
                Try for Free
              </a>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=1"
                className="banks_purchase display_none"
              >
                Purchase Now
              </a>
            </div>
            <div class="pricing_item_wrapper">
              <ul class="pricing_item_title">
                <li>Number of Operating Licenses</li>
                <li>New Customers per Month</li>
                <li>New Applications per Month</li>
                <li>New Loans Made per Month</li>
                <li>Access to Automated Email and Text</li>
                <li>Access to Cash Flow Analytical Tools</li>
                <li>Access to Collaboration Tools</li>
                <li>Access to Digital Marketing Tools</li>
                <li>Access to Supported Financial Products</li>
                <li>Access to Consumer Credit Reporting *</li>
                <li>Access to Management Reporting</li>
                <li>Smart ACH Transfers *</li>
              </ul>
              <ul class="pricing_item_value">
                <li>Up to 2</li>
                <li>Up to 50</li>
                <li>Up to 40</li>
                <li>Up to $200,000</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Limited</li>
                <li>Limited</li>
                <li>Limited</li>
                <li>Not Available</li>
              </ul>
            </div>
          </li>
          {/* <li className="price_li">
                        <div className="price_li_div">
                            <h2>Standard</h2>
                            <div className="title_price">
                                $650 per Month<br/>
                                $4,000 One - Time Set Up Fee
                            </div>
                            <a rel="" href="https://institution.happymangocredit.com/register/banker?sRegisterType=2"
                               className="banks_freetrial">Try for Free</a>
                            <a rel="" href="https://institution.happymangocredit.com/register/banker?sRegisterType=2"
                               className="banks_purchase display_none">Purchase Now</a>
                        </div>
                        <ul>
                            <li>Up to 3</li>
                            <li>Unlimited</li>
                            <li>Up to 80</li>
                            <li>Up to $400,000</li>
                        </ul>
                    </li> */}

          <li className="price_li">
            <div className="price_li_div">
              <h2>Standard</h2>
              <div className="title_price">
                $1,095 per Month
                <br />
                $7,500 One - Time Set Up Fee
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=3"
                className="banks_freetrial"
              >
                Try for Free
              </a>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=3"
                className="banks_purchase display_none"
              >
                Purchase Now
              </a>
            </div>
            <div class="pricing_item_wrapper">
              <ul class="pricing_item_title">
                <li>Number of Operating Licenses</li>
                <li>New Customers per Month</li>
                <li>New Applications per Month</li>
                <li>New Loans Made per Month</li>
                <li>Access to Automated Email and Text</li>
                <li>Access to Cash Flow Analytical Tools</li>
                <li>Access to Collaboration Tools</li>
                <li>Access to Digital Marketing Tools</li>
                <li>Access to Supported Financial Products</li>
                <li>Access to Consumer Credit Reporting *</li>
                <li>Access to Management Reporting</li>
                <li>Smart ACH Transfers *</li>
              </ul>
              <ul class="pricing_item_value">
                <li>Up to 5</li>
                <li>Up to 150</li>
                <li>Up to 120</li>
                <li>Up to $600,000</li>
                <li>Full Access </li>
                <li>Full Access </li>
                <li>Full Access </li>
                <li>Full Access </li>
                <li>Full Access </li>
                <li>Full Access </li>
                <li>Full Access </li>
                <li>Up to 50</li>
              </ul>
            </div>
          </li>
          <li className="price_li">
            <div className="price_li_div">
              <h2>Tailored</h2>
              <div className="title_price">
                <a rel="" href="tel:+12129711381;" className="banks_purchase">
                  Call Us (212) 971-1381
                </a>
                <br />
                <a
                  rel=""
                  href="mailto:contact@happymangocredit.com"
                  className="banks_purchase"
                >
                  Email Us
                </a>
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=4"
                className="banks_freetrial"
              >
                Try for Free
              </a>
            </div>
            <div class="pricing_item_wrapper">
              <ul class="pricing_item_title">
                <li>Number of Operating Licenses</li>
                <li>New Customers per Month</li>
                <li>New Applications per Month</li>
                <li>New Loans Made per Month</li>
                <li>Access to Automated Email and Text</li>
                <li>Access to Cash Flow Analytical Tools</li>
                <li>Access to Collaboration Tools</li>
                <li>Access to Digital Marketing Tools</li>
                <li>Access to Supported Financial Products</li>
                <li>Access to Consumer Credit Reporting *</li>
                <li>Access to Management Reporting</li>
                <li>Smart ACH Transfers *</li>
              </ul>
              <ul class="pricing_item_value">
                <li>As Needed</li>
                <li>Unlimited</li>
                <li>150 or More</li>
                <li>$600,000 or More</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>Full Access</li>
                <li>As Needed</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <footer className="footer_position">
        <Footer />
      </footer>
    </div>
  </>
)
